import * as React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'

const Seo = ({ description, lang, meta, title }) => {
  const metaDescription = description
  return (
    <>
      <Helmet
        htmlAttributes={{
          lang,
        }}
        title={title}
        titleTemplate={title}
        meta={[
          {
            name: `description`,
            content: metaDescription,
          },
          {
            name: 'google-site-verification',
            content: 'YQQoTPV9Vjb3ytuPnubCzKjRjhumP2Two0bsZYah4Rc'
          }
        ].concat(meta)}
      >
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link
        rel="preconnect"
        href="https://fonts.gstatic.com"
        crossOrigin="true"
      />
      <link
        href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800&display=swap"
        rel="stylesheet"
      />
      <link
        rel="stylesheet"
        type="text/css"
        charSet="UTF-8"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
      />
      <link
        rel="stylesheet"
        type="text/css"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
      />
      <link
        rel="shortcut icon"
        href="/images/favicon.ico"
        type="image/x-icon"
      />
      <iframe
        src="https://www.googletagmanager.com/ns.html?id=GTM-MZHM3H9"
        height="0"
        width="0"
        style={{ display: 'none', visibility: 'hidden' }}
      ></iframe>
      </Helmet>
    </>
  )
}

Seo.defaultProps = {
  lang: `ru`,
  meta: [],
  description: `Подберем для Вас лучший автомобиль в Украине, проверим и возьмем на себя юридические моменты`,
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default Seo
